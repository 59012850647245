@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
/*popup styles*/
.upgrade-poppic{
display:flex;
width:50%;
}
.upgrade-poppic img{
object-fit: contain;
  }
 .upgrade-statstable{
  flex: 1 1 0%;
display: flex;
flex-direction: column;
width: 275px;
border: 1px solid rgb(153, 255, 50);
border-radius: 5px;
 } 
/*dash styles*/
.team-dash-r .team-dash{
  width: 100%!important;
  display: flex;
justify-content: center;
}
.team-dash .row{
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0;
  border: none !important;
}
/*dashleague table*/
.db-lt-pos{
  width: 5%;
  justify-content: center;
  display: flex; 
}
.db-lt-avatar{
  width: 8%;
}
.db-lt-teamname{
  width: 40%;  
}
.db-lt-wld{
  width: 5%; 
  justify-content: center;
display: flex; 
}
.db-lt-points{
  width:10%;
  justify-content: center;
display: flex;
}
.db-lt-play{
  width: 22%;
  justify-content: center;
display: flex;
}
.db-lt-row{
 height:40px;
 transition: all 0.2s linear 0s;
}
.db-lt-row:hover{
  background: #ffffff24;
 }
.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.green-btn {
  color: rgba(0, 0, 0, 0.00);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12)!important;
  border: none;
  color: transparent;
  background: none;
  text-shadow: none;
  box-shadow: none !important;
}
.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.green-btn::before {
background:none!important;
}
.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.blue-btn {
  color: rgba(0, 0, 0, 0.00);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12)!important;
  border: none;
  color: transparent;
  background: none;
  text-shadow: none;
  box-shadow: none !important;
}
.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.blue-btn::before {
background:none!important;
}
/*chemistry colours*/
.chem1{border-color: #b8c2cc;}
.chem2{border-color: #4dc0b5;}
.chem3{border-color: #38c172;}
.chem4{border-color: #ffed4a;}
.chem5{border-color: #f6993f;}
.chem8{border-color: #e3342f;}
/*green button*/
button.green-btn{
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #000B1D;
  letter-spacing: 0px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background: #72dd07 none repeat scroll 0% 0%;
  border: 2px solid #57a20d;
  border-radius: 6px !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.91) inset, 0 1.25rem 0 rgba(255, 255, 255, 0.08) inset, 
  0 -1.25rem 1.25rem rgba(0, 0, 0, 0.47) inset, 
  0 1.25rem 1.25rem rgba(255, 255, 255, 0.2) inset !important;

  transition: all 0.2s linear 0s;
  text-align: center;
  text-decoration: none;
}
button.green-btn:hover,
button.green-btn:focus {
  text-decoration: none;
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.75), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.59) inset, 
  0 20px 0 rgba(255, 255, 255, 0.18) inset, 
  0 -20px 20px rgba(0, 0, 0, 0.15) inset, 0 20px 20px rgba(255, 255, 255, 0.05) inset !important;
    border-width:2px;
}

button.green-btn::before {
  content: "";
  display: block;
  position: absolute;
  background:linear-gradient( rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%)!important;
  top: 0;
  left: 10%;
  height: 1px;
  width: 50%;
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out 0s;
}
button.green-btn:hover::before {
  left: 45%;
}
/*red button*/
button.red-btn{
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background: #ff0000 none repeat scroll 0% 0%;
  border: 2px solid #cc0000;
  border-radius: 6px !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.91) inset, 0 1.25rem 0 rgba(255, 255, 255, 0.08) inset, 
  0 -1.25rem 1.25rem rgba(0, 0, 0, 0.47) inset, 
  0 1.25rem 1.25rem rgba(255, 255, 255, 0.2) inset !important;

  transition: all 0.2s linear 0s;
  text-align: center;
  text-decoration: none;
}
button.red-btn:hover,
button.red-btn:focus {
  text-decoration: none;
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.75), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.59) inset, 
  0 20px 0 rgba(255, 255, 255, 0.18) inset, 
  0 -20px 20px rgba(0, 0, 0, 0.15) inset, 0 20px 20px rgba(255, 255, 255, 0.05) inset !important;
    border-width:2px;
    border-color:#ff4a4a;
    background-color: #f72222;
}

button.red-btn::before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient( rgba(249, 107, 107, 0) 0%,rgb(245, 166, 166) 50%,rgba(255,255,255,0) 100%) !important;
  top: 0;
  left: 10%;
  height: 1px;
  width: 50%;
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out 0s;
}
button.red-btn:hover::before {
  left: 45%;
}
/*blue button*/
button.blue-btn{
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background: #0a61be none repeat scroll 0% 0%;
  border: 2px solid #054d8d;
  border-radius: 6px !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.91) inset, 0 1.25rem 0 rgba(255, 255, 255, 0.08) inset, 
  0 -1.25rem 1.25rem rgba(0, 0, 0, 0.47) inset, 
  0 1.25rem 1.25rem rgba(255, 255, 255, 0.2) inset !important;
  min-height:40px;
  transition: all 0.2s linear 0s;
  text-align: center;
  text-decoration: none;
}
button.blue-btn:hover,
button.blue-btn:focus {
  text-decoration: none;
  color: #fff;
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.75), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.59) inset, 
  0 20px 0 rgba(255, 255, 255, 0.18) inset, 
  0 -20px 20px rgba(0, 0, 0, 0.15) inset, 0 20px 20px rgba(255, 255, 255, 0.05) inset !important;
    border-width:2px;
    border-color:#009fff;
    background-color: #1657B3;
}

button.blue-btn::before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient( rgba(249, 107, 107, 0) 0%,rgb(166, 204, 245) 50%,rgba(255,255,255,0) 100%) !important;
  top: 0;
  left: 10%;
  height: 1px;
  width: 50%;
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out 0s;
}
button.blue-btn:hover::before {
  left: 45%;
}
/*orange button*/
button.orange-btn{
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background: #FFA500 none repeat scroll 0% 0%;
  border: 2px solid #FFA500;
  border-radius: 6px !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.91) inset, 0 1.25rem 0 rgba(255, 255, 255, 0.08) inset, 
  0 -1.25rem 1.25rem rgba(0, 0, 0, 0.47) inset, 
  0 1.25rem 1.25rem rgba(255, 255, 255, 0.2) inset !important;
padding:1px 10px;
  transition: all 0.2s linear 0s;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
button.orange-btn:hover,
button.orange-btn:focus {
  color: #fff;
  text-decoration: none;
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.75), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 
  0 0 0 rgba(0, 0, 0, 0.59) inset, 
  0 20px 0 rgba(255, 255, 255, 0.18) inset, 
  0 -20px 20px rgba(0, 0, 0, 0.15) inset, 0 20px 20px rgba(255, 255, 255, 0.05) inset !important;
    border-width:2px;
    border-color:#f90;
    background-color: #FFA500;
}

button.orange-btn::before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient( rgba(249, 107, 107, 0) 0%,rgb(166, 204, 245) 50%,rgba(255,255,255,0) 100%) !important;
  top: 0;
  left: 10%;
  height: 1px;
  width: 50%;
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out 0s;
}
button.orange-btn:hover::before {
  left: 45%;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
 background: #22395A;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #99ff32; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #72dd07; 
}
.css-13jm8c7-MuiImageListItem-root .MuiImageListItem-img {
  object-fit: contain;
}
/*dialogue styles*/
.css-1y28ifk-MuiPaper-root-MuiDialog-paper {
  border-radius: 5px;
  border: solid 1px #99ff32;
}
.css-ed8v5b-MuiPaper-root-MuiDialog-paper {
  border-radius: 5px;
  border: solid 1px #99ff32;
}
.css-zq86lr-MuiPaper-root-MuiDialog-paper{
  border-radius: 5px;
  border: solid 1px #99ff32;
}
.css-1qr519w{
  border-radius: 5px;
  border: solid 1px #99ff32;
}

.css-1grg3cz-MuiTypography-root-MuiDialogTitle-root {
  margin: 0;
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: solid 1px #99ff32;
  margin-bottom:20px!important;
}
/*grid styles*/

.css-1t3cjmf-MuiPaper-root-MuiDialog-paper{
  background-color: transparent!important;
}
.css-1v0xx4m-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  align-items: center;
  display: flex;
}
.maindb{
  background-image: url("/public/images/pitch-bg.png");
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
}
.css-mbi4e9-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
  background: #22395a;
}
.css-mbi4e9-MuiDataGrid-root .MuiDataGrid-cell {
  border: 1px solid #001636;
}

.css-1fzdrei-MuiLinearProgress-root {
height:5px!important;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 37px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

}

.modal-content {
  background-color: #fff;
  margin: auto;
padding: 40px 0px;
  width: 1120px!important;
display: flex;
justify-content: center;
flex-direction: column;
border-radius: 10px!important;
}

.modal-close{
  top: 16px;
  position: absolute;
  right: -22px;
}

/*results modal*/
.results-modal{
	background: #fff;
	padding: 0px;
	width: 1120px;
	z-index: 0;
display: flex;
justify-content: center;
}
.results-top{
	height: 202px;
	margin-top: -75px;
	margin-left: -18px;
	position: relative;
}
.results-score-row{
	background: #000;
	border-radius: 90px;
	width: 90%;
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
z-index: 5;
margin-top: -84px;
	flex-direction: row;
display: flex;
	padding: 10px 10px;
}
.team1-wrap{
  flex-grow: 2;	
  width: 40%;
  }
  .team1{
    color: #fff;
    text-align: right;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
  }
  .manager1{
    color: #999;
    text-align: right;
  }
  .team2-wrap{
  flex-grow: 2;	
  width: 40%;
  }
  .team2{
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
  }
  .manager2{
    color: #999;
  }
.scoreboard{
flex-grow: 1;
	z-index: 6;
  background-image: linear-gradient(to bottom, transparent 50%, #000 50%), linear-gradient(to right, #222 50%, #000 50%);
  background-size: 5px 5px, 5px 5px;
	border: ridge 5px #333;
	border-radius: 10px;
box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
	margin: -20px 20px;
display: flex;
align-items: center;
justify-content: center;
width: 20%;
}
.score{
text-align: center;
width:120px;
font-size: 60px;
font-weight: bold;
color: #9f3;	
}
.results-lists{
	display: flex;
	flex-direction: row;
  height: 350px;
  padding: 0px 60px;
  
}
.team1-list{
	flex-grow: 1;
	padding-right: 3px;
}
.team2-list{
	flex-grow: 1;
	padding-left: 3px;
}
/* CSS Document */
body {
  margin: 0;
}
.login-box{
  background:#000C1D;
  box-shadow:0px 10px 28px 0px rgba(0,0,0,0.75);
  padding: 100px 40px 40px!important;
}
.login-preload{
  background:#041021;
  box-shadow:0px 10px 28px 0px rgba(0,0,0,0.75);
  padding: 0px 40px 20px 40px!important; 
}

.login-bg{
	background-image: url("/public/images/login-screen-bg.png");
	background-position: center center;
	background-size: cover;
}
.login-bg-nologo{
	background-image: url("/public/images/login-screen-nologo-bg.png");
	background-position: center center;
	background-size: cover;
}
button.login-btn{
	background-color:#fff;
	border:2px solid #001636;
	display:inline-block;
	cursor:pointer;
	color:#001636;
	font-size:21px;
	text-transform: uppercase;
	padding:12px 49px;
	text-decoration:none;	
	border-radius: 4px;
	-webkit-box-shadow: 0px 10px 28px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 28px 0px rgba(0,0,0,0.75);
box-shadow: 0px 10px 28px 0px rgba(0,0,0,0.75);
}
button.login-btn:hover {
	background-color:#99FF33;
	color: #001636;
}
button.login-btn:active {
	position:relative;
	top:1px;
}
ol li{
	padding-bottom:10px;
}
/*icon box styles*/
.icon-box{
	flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 5px 5px 0px 5px;
  gap:2px;

}
.icons-left{
	flex-direction: column;
	display: flex;
justify-content: space-between;
background-color: rgba(0,0,0,0.20);
}
.icon-tr{
  background-color: rgba(0,0,0,0.20);
}
.card-box-sm div.icon-box{
  padding: 2px;
}
/* Flex Layout */
.pitchbg{
  background: radial-gradient(circle, rgb(76, 145, 11) 0%, rgb(24, 46, 4) 63%);
}
 #main {
    display: flex;
    min-height: calc(100vh - 110px);
  }
  #main > article {
    flex: 1;
	  margin-top:10px;
justify-content: center;
display: flex;	  
  }
  #main > aside {
    flex: 0 0 20vw;
  }
#main > nav {
  order: -1;
  background: repeating-linear-gradient( -45deg, #001636, #001636 50px, #010b1b 50px, #010b1b 100px );
}
footer{
height: 50px;	
background: #000b1d;
justify-content: flex-start;
 align-items: center;
display: flex;	
	padding-left: 10px;
}
  header {
    background: #000b1d;
	  border-bottom:1px solid #99ff32;
    height: 59px;
  }
.headbox {
  display: flex;
  justify-content: space-between;
	  align-items: center;
	  padding: 0px;
}
.team-name{
	font-weight: bold;
  text-transform: uppercase;
}
.headright {
  display: flex;
  flex-direction: row;
	  align-items: center;
}
.headattributes {
  display: flex;
  flex-direction: row;
  padding: 2px;
  font-size: 14px;
}
button.boost-btn{
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  background: #00000087;
  border: none;
  color: #fff;
  margin-top: 7px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
}
button.boost-btn:hover{
  background: #99ff32;
  color: #000b1d;
}
.boost100block{
  background: rgb(164,124,73);
  background: linear-gradient(90deg, rgba(101,77,29,1) 0%, rgba(164,124,73,1) 100%);
  padding: 10px;
  border: 1px solid #000;
  display: flex;
flex-direction: column;
text-align: center;
width:33%;
}
.boost250block{
  background: rgb(175,182,175);
  background: linear-gradient(90deg, rgba(120,120,120,1) 0%, rgba(175,182,175,1) 100%);
  padding: 10px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  width:33%;

}
.boost500block{
  background: rgb(211,171,37);
  background: linear-gradient(90deg, rgba(158,123,7,1) 0%, rgba(211,171,37,1) 100%);
  padding: 10px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  width:33%;
}
.headattributes div a{
	color:#99ff32;
}
.headattributes div a:hover{
	color:#72dd07;
}
.headsettings {
  background: #99ff32;
	display: flex;
flex-direction: row;
		  align-items: center;
}
.head-id{
display: flex;
flex-direction: column;
padding: 0px 20px 0px 20px;
color:#000B1D;
font-size: 12px;
}
.headdropmenu{
		display: flex;
flex-direction: row;
}
/*Settings dropdown*/
.fa-cog{
	color:#000B1D;
  font-size: 42px !important;
  margin-right: 0px !important;
}
/* The dropdown container */
.drop2 {
  float: left;
  overflow: hidden;
border-left: 1px solid #000B1D !important;
}

/* Dropdown button */
.drop2 .dropbtn2 {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 8px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
		cursor: pointer;
}

/* Add a red background color to navbar links on hover */
.drop2:hover .dropbtn2 {
  background-color: #72dd07;
}

/* Dropdown content (hidden by default) */
.drop2-content {
  display: none;
  position: absolute;
  background-color: #000B1D;
  min-width: 223px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
	right: 0px;
	border-left: 1px solid #99ff32 !important;
	border-right: 1px solid #99ff32 !important;
}

/* Links inside the dropdown */
.drop2-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
	border-bottom: 1px solid #fff !important;
}

/* Add a grey background color to dropdown links on hover */
.drop2-content a:hover {
 color:#99ff32;
	border-bottom: 1px solid #99ff32 !important;
}

/* Show the dropdown menu on hover */
.drop2:hover .drop2-content {
  display: block;
}

/* Fixed left sidenav, full height */
.sidenav {
  height: auto;
  width: 17vw;
  z-index: 1;
  left: 0;
  background-color: #000b1d;
  overflow-x: hidden;
  padding-top: 0px;
	margin-top:10px;
}
.fa{margin-right:10px;}
/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 10px 10px 10px 20px;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
	border-bottom:1px solid #fff;
font-family: 'Montserrat', sans-serif;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #99ff32;
}
/* Add an active class to the active dropdown button */
.active {
  color: #99ff32;
border-bottom:1px solid #99ff32;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #000b1d;
padding: 0 20px 10px 0px;
}


/* Optional: Style the caret down icon */
.fa-angle-down {
  float: right;
  padding-right: 10px;
}
/*Buttons*/
button.update-btn{
border-radius: 0;
border: none;
padding: 15px;
color: #000b1d;
background: #99ff32;
font-weight: 600;
font-family: 'Montserrat', sans-serif;
	margin-bottom:15px;
	cursor: pointer;
}
button.update-btn:hover{
background:#72dd07;
}
button.ready-btn{
border-radius: 0;
border: none;
padding: 15px;
color: #fff;
background: #cc0000;
font-weight: 600;
font-family: 'Montserrat', sans-serif;
	margin-bottom:15px;
		cursor: pointer;
}
button.ready-btn:hover{
background:#990000;
}
/*button.green-btn {
  border-radius: 0;
  border: none;
  padding: 8px;
  color: #000b1d;
  background: #99ff32;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
}
button.green-btn:hover{
background:#72dd07;
}*/
/*Right Sidebar*/

.right-sidebar{
display: flex;
flex-direction: column;	
	margin-top: 20px;
	margin-right: 20px;
}

/* Select Container The container must be positioned relative: */
.custom-select {
  position: relative;
	font-family: 'Montserrat', sans-serif;
	 border: 1px solid #99ff32;
	margin-bottom: -20px;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: #000b1d;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border-bottom: 1px solid #99ff32;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #000b1d;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
border-right: 1px solid #99ff32;
border-left: 1px solid #99ff32;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: #001636;
	color:#99ff32;
}
/*Table styles*/
table.playerstats-table{
	margin-top: 0px;
	/*-webkit-box-shadow: 0px 1px 0px 0px rgba(153,255,50,1);
-moz-box-shadow: 0px 1px 0px 0px rgba(153,255,50,1);
box-shadow: 0px 1px 0px 0px rgba(153,255,50,1);*/
}
table.attributes-table tr{
  border: 1px solid #22395a;
  background-color: transparent!important;
	padding: 3px;
}
table.attributes-table td.td-title{
	text-align: right;
	font-weight: 600;
  font-size: 14px;
		border-left: 0px solid #99ff32;
    background-color: transparent!important;
}
table.attributes-table td.td-values{
	text-align: left;
	font-weight: 400;
  font-size: 14px;
  background-color: transparent!important;
		border-right: 0px solid #99ff32;
}
table.attributes-table td.totalright{
	text-align: left;
	font-weight: 400;
	/*	border-right: 1px solid #99ff32;
	border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32;
	background-color: transparent!important;
	color: #99ff32;
}
table.attributes-table td.totalleft{
	text-align: right;
	font-weight: 600;
	 background-color: transparent!important;
		/*border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32;
}
table.attributes-table th {
	padding: 10px;
	text-transform: uppercase;
	font-size: 12px;
	border-bottom: 1px solid rgba(143, 255, 250, 0.44)!important;
	border-top: 1px solid rgba(143, 255, 250, 0.44)!important;
  text-align: center!important;
}
.selectPlayerName{
  box-shadow: rgba(0, 0, 0, 0.7) 0px 25px 20px -10px!important; 
}
table.playerstats-table th {
	padding: 10px;
	text-transform: uppercase;
	font-size: 12px;
	border-bottom: 1px solid rgba(143, 255, 250, 0.44)!important;
	border-top: 1px solid rgba(143, 255, 250, 0.44)!important;
  text-align: center!important;
}
table.playerstats-table td, tr {
  border: 0px solid #22395a;
  background-color: transparent!important;
	padding: 3px;
}
td, tr {
  border: 1px solid #22395a;
  background-color: #000b1d;
	padding: 3px;
}
th {
	padding: 10px;
	text-transform: uppercase;
	font-size: 12px;
	border-bottom: 1px solid #99ff32!important;
	border-top: 1px solid #99ff32!important;
  text-align: center!important;
}
td.td-title{
	text-align: right;
	font-weight: 600;
  font-size: 14px;
		border-left: 0px solid #99ff32;
}
td.td-values{
	text-align: left;
	font-weight: 400;
  font-size: 14px;
		border-right: 0px solid #99ff32;
}
td.totalright{
	text-align: left;
	font-weight: 400;
	/*	border-right: 1px solid #99ff32;
	border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32!important;
	background-color: #001636;
	color: #99ff32;
}
table.playerstats-table td.totalleft{
	text-align: right;
	font-weight: 600;
	 background-color: #001636;
		/*border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32!important;
}
table.playerstats-table td.totalright{
	text-align: left;
	font-weight: 400;
	/*	border-right: 1px solid #99ff32;
	border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32!important;
	background-color: #001636;
	color: #99ff32;
}
td.totalleft{
	text-align: right;
	font-weight: 600;
	 background-color: #001636;
		/*border-bottom: 1px solid #99ff32;*/
		border-top: 1px solid #99ff32!important;
}
table {
  width: 100%;
	  border: 0px solid #99ff32;
	 border-collapse: collapse;
   color:#fff;
}
/*results table styles*/
table.results-table{
  width: 100%;
 border-collapse: collapse;
}
table.results-table tbody tr{
  border: 2px solid #fff;
  background-color: #f2f2f2;
  padding: 2px 5px;
  color: #000b1d;
  font-size: 12px; 
}
table.results-table tbody tr td{
  border: 2px solid #fff;
  background-color: #f2f2f2;
  padding: 2px 5px;
  color: #000b1d;
  font-size: 12px; 
}
table.results-table tbody tr th{
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: 12px;
  border: 2px solid #fff;
  background: #ebebeb; 
}
/*score table styles*/
.score-res{
  font-size: 14px!important;
  font-weight:600;
  text-align: center;
  background-color: #22395a;
}
table.score-table tbody tr td.score{
  background-color: #001636!important;
}
.win{
  color: #72DD07;
}
.manager{
  color:#ccc;
  font-size: 10px!important;
}
table.score-table{
  width: 100%;
 border-collapse: collapse;

}
table.score-table tbody tr{
  padding: 2px 5px;
  color: #fff;
  font-size: 12px; 
  border-top: 2px solid #001636!important;
  border-bottom: 2px solid #001636!important;
}
table.score-table tbody tr td{
  border-right: 0px solid #001636;
  background-color: #22395a;
  padding: 2px 5px;
  color: #fff;
  font-size: 12px; 
}
table.score-table tbody tr th{
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: 12px!important;
  border: 2px solid #22395a;
  background: #000b1d; 
  color: #fff;
}
.pitch-positions{
	 display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
text-align: right;
font-size: 5em;
font-weight: 900;
color: #fff;
opacity: 0.1;
}
.pitch-total{display:flex;}
/*--4-4-2 layout--*/

.pitch-wrapper {
  /*margin: auto 15px;*/
	background-image: url("/public/images/New-pitch-Large.jpg");
	background-attachment: scroll;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	width: auto;
	height: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;
border: solid 5px #000b1d;
box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.65);
padding-top: 10px;
color: #fff;
z-index: 5;
position: relative;
border-radius: 10px;
}
.formation{
background: #99ff33;
color: #000;
padding: 2px 10px;
	font-size:15px;
}
/*lg player box on selected player */
.player-columnLG {
  /*height: 130px;*/
	display: flex;
align-items: flex-end;
margin:10px 0 0 0;
border-bottom: 1px solid rgba(143, 255, 250, 0.44);
}
/*-- card layout---*/
.card-boxLG{
  text-align: center;
  width: 130px;
  margin: auto;
  }

.card-boxLG p a{
	font-size:11px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
  line-height: 1em;
}
.card-boxLG p a:hover{
	font-size:11px;
	color: #fff;
	text-decoration: underline;
	font-weight: 600;
}
.card-boxLG p{
margin:2px;
	padding: 2px;
  margin-top: 0px;
  background-color: rgba(0,0,0,0.0);
  border: solid 0px rgba(0,0,0,0.65);
}
.flip-box-frontLG img{
  width: 100px;
  height: 150px;	
  margin-bottom: -5px;
}
.card-boxLG span{font-size: 11px;}
/*ends*/
.card-box span{font-size: 9px;}

.row {
  display: flex;
  flex-direction: row;
	align-items: flex-end;
   flex-wrap: wrap; 
  width: 100%;
}
.row2 {
  display: flex;
  flex-direction: row;
	align-items: normal;
   flex-wrap: wrap; 
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.player-column {
  height: 65px;
	display: flex;
align-items: flex-end;
}
.empty-column {
  height: 65px;
	display: flex;
align-items: flex-end;
}
/*-- card layout---*/
.card-box{
  text-align: center;
  width: 90px;
  margin: auto;
  transition: transform ease 300ms;
  backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(2px);
border: 2px solid rgba(143, 255, 250, 0.60);
background: rgb(255,255,255);
background: linear-gradient(95deg, rgba(255, 255, 255, 0.35) 47%, rgba(204, 245, 254, 0.2) 48%);
box-shadow: rgba(0, 0, 0, 0.95) 0px 25px 20px -20px;
border-radius: 5px;
  }
  .card-box:hover,
  .card-box:focus,
  .card-box:active
  {
    text-align: center;
    width: 90px;
    margin: auto;
    background-color: rgba(0,0,0,0.35);
    border: 2px solid rgba(143, 255, 250, 0.90);
    transform: translate(0, -5px);
    -webkit-box-shadow:0px 0px 20px 1px rgba(45,255,196,0.9);
-moz-box-shadow: 0px 0px 20px 1px rgba(45,255,196,0.9);
box-shadow: 0px 0px 20px 1px rgba(45,255,196,0.9);
    }
    .card-box::before {
      content: "";
      display: block;
      position: absolute;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
      top: 1px;
      left: 10%;
      height: 1px;
      width: 60%;
      transition: all 0.3s ease-in-out 0s;
    }
    .card-box:hover::before,
    .card-box:focus::before,
    .card-box:active::before
    {
      left: 45%;
    }
    

.card-box p a{
	font-size:9px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
}
.card-box p a:hover{
	font-size:9px;
	color: #fff;
	text-decoration: underline;
	font-weight: 600;
}
.card-box p{
   margin: 2px 0px;
    padding: 2px;
    border: solid 0px rgba(0,0,0,0.65);
    line-height: 9px;
}
/*-- card layout small use on 4 5 1 for example any formation with 5 in a row---*/
.card-box-sm{
  text-align: center;
  width:90px;
  margin: auto;
  background-color: rgba(0,0,0,0.05);
  border: solid 1px rgba(255, 255, 255, 0.20);
  transition: transform ease 300ms;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 2px solid rgba(143, 255, 250, 0.60);
  background: rgb(255,255,255);
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.35) 47%, rgba(204, 245, 254, 0.2) 48%);
  box-shadow: rgba(0, 0, 0, 0.95) 0px 25px 20px -20px;
  border-radius: 5px;
  }
  .card-box-sm:hover,
  .card-box-sm:focus,
  .card-box-sm:active
  {
    text-align: center;
    width: 90px;
    margin: auto;
    background-color: rgba(0,0,0,0.35);
    border: 2px solid rgba(143, 255, 250, 0.90);
    transform: translate(0, -5px);
    -webkit-box-shadow:0px 0px 20px 1px rgba(45,255,196,0.9);
-moz-box-shadow: 0px 0px 20px 1px rgba(45,255,196,0.9);
box-shadow: 0px 0px 20px 1px rgba(45,255,196,0.9);
    }
  .card-box-sm::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    top: 1px;
    left: 10%;
    height: 1px;
    width: 60%;
    transition: all 0.3s ease-in-out 0s;
  }
  .card-box-sm:hover::before,
  .card-box-sm:focus::before,
  .card-box-sm:active::before
  {
    left: 45%;
  }
  /*.card-box-sm:hover{
    text-align: center;
    width: 90px;
    margin: auto;
    background-color: rgba(0,0,0,0.35);
    border: solid 1px rgba(255, 255, 255, 0.80);
    transform: translate(0, -5px);
    }*/
  .card-box-sm p a{
    font-size:9px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;

  }
  .card-box-sm p a:hover{
    text-decoration: underline;
  }
  .card-box-sm p{
    background-color: rgba(0,0,0,0.0);
  margin:2px 0px;
    padding: 2px;
    border: solid 0px rgba(0,0,0,0.65);
    line-height: 9px;
  }
  .card-box-sm span{font-size: 9px;}

/*button.levelup-btn{
	width:67px;
	background: transparent;
	font-size:12px;
	color: white;
	border:solid 1px white;
	padding:2px;
	cursor: pointer;
}*/
button.swap-btn{
	width:90%;
  box-shadow:inset 0px 1px 0px 0px #f29c93;
  background:linear-gradient(#fe1a00, #ce0100);
	font-size:12px;
	color: white;
	  border-width:1px;
  border-color:#d83526;
	padding:2px;
	margin: 3px auto;
	cursor: pointer;
	font-weight: 500;
}
button.swap-btn:hover{
  background: linear-gradient(#ce0100, #fe1a00);	
}
button.levelup-btn{
	width:90%;
  box-shadow:inset 0px 1px 0px 0px #caefab;
  background:linear-gradient(#77d42a, #5cb811);
	font-size:12px;
	color: #003300;
	  border-width:1px;
  border-color:#268a16;
	padding:2px;
	margin: 2px auto;
	cursor: pointer;
	font-weight: 500;
}
button.levelup-btn:hover {
  background: linear-gradient(#5cb811, #77d42a);
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
  background-color: transparent;
  width: 34px;
  height: 50px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
	
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
box-shadow: 0 30px 60px -12px rgba(0,0,0,.55),0 18px 36px -18px rgba(0,0,0,.4)!important;
}
.flip-box-front img{
  width: 34px;
  height: 50px;	
}

.flip-box-front-wrongpos img{
  width: 34px;
  height: 50px;		
  border: solid 4px orange;
}

.flip-box-front-invalidasset img{
  width: 34px;
  height: 50px;		
  border: solid 4px red;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
/* .flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
} */

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  font-size: small;
  width: 34px;
  height: 50px;	
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  background-color: #001636;
  color: black;
}

/* Style the back side */
.flip-box-back {
  background-color: #001636;
  color: white;
  transform: rotateY(180deg);
	text-align: center;
		border: solid 2px #99ff33;
}


/*thumbs layouts*/
.th-pitch-wrapper-center{
  margin: auto 15px;
	background-image: url("/public/images/New-pitch-Large.jpg");
	background-attachment: scroll;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
max-width: 275px;
height: 384px;
display: flex;
flex-wrap: wrap;
align-items: start;
	border: solid 1px #99ff33;
}
/*--4-4-2 layout--*/
.th-pitch-wrapper {
 
	background-image: url("/public/images/New-pitch-Large.jpg");
	background-attachment: scroll;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
max-width: 275px;
height: 384px;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
}
.th-row {
  display: flex;
  flex-direction: row;
	align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.th-player-column {  height: 65px;
	display: flex;
align-items: flex-end;
}
.th-empty-column {  
	height: 65px;
	display: flex;
align-items: flex-end;
}
/*-- card layout---*/
.th-card-box{
text-align: center;
width: 44px;
margin: auto;
}
.th-card-box p a{
	font-size:11px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
}
.th-card-box p a:hover{
	font-size:11px;
	color: #fff;
	text-decoration: underline;
	font-weight: 600;
}
.th-card-box p{
	background-color: rgba(0,0,0,0.15);
margin:2px;
	padding: 2px;
}
.th-card-box span{font-size: 10px;}
/*-- card layout small use on 4 5 1 for example ---*/
.th-card-box-sm{
text-align: center;
width: 112px;
margin: auto;
}
.th-card-box-sm p a{
	font-size:11px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
}
.th-card-box-sm p a:hover{
	font-size:11px;
	color: #fff;
	text-decoration: underline;
	font-weight: 600;
}
.th-card-box-sm p{
	background-color: rgba(0,0,0,0.15);
margin:2px;
	padding: 2px;
}
.th-card-box-sm span{font-size: 10px;}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.th-flip-box {
  background-color: transparent;
width: 44px;
height: 65px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
	margin: auto;
}

/* This container is needed to position the front and back side */
.th-flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
box-shadow: 0 30px 60px -12px rgba(0,0,0,.55),0 18px 36px -18px rgba(0,0,0,.4)!important;
}
.th-flip-box-front img{
width: 44px;
height: 65px;	
}
.th-flip-box-front-sm img{
  width: 34px;
  height: 50px;	
  }
  



/*progress bar - delete*/
.progress100 {
  background: rgba(255,255,255,0.2);
  justify-content: flex-start;
  border-radius: 60px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 100px;
	margin: auto;
	margin-bottom: -7px;
}

.progress-value100 {
  animation: load100 3s normal forwards;
box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.30);
  border-radius: 60px;
background: #99ff33;
  height: 5px;
  width: 0;
}

@keyframes load100 {
  0% { width: 0; }
  100% { width: 100%; }
}
.progress50 {
  background: rgba(255,255,255,0.2);
  justify-content: flex-start;
  border-radius: 60px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 100px;
	margin: auto;
	margin-bottom: -7px;
}

.progress-value50 {
  animation: load50 3s normal forwards;
box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.30);
  border-radius: 60px;
background: #ff8a00;
  height: 5px;
  width: 0;
}

@keyframes load50 {
  0% { width: 0; }
  100% { width: 50%; }
}
.progress25 {
  background: rgba(255,255,255,0.2);
  justify-content: flex-start;
  border-radius: 60px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 100px;
	margin: auto;
	margin-bottom: -7px;
}

.progress-value25 {
  animation: load25 3s normal forwards;
box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.30);
  border-radius: 60px;
background: red;
  height: 5px;
  width: 0;
}

@keyframes load25 {
  0% { width: 0; }
  100% { width: 25%; }
}

@keyframes load75 {
  0% {
      width: 0;
  }
  100% {
      width: 75%;
  }
}

/*Dashboard styles
main {
 flex: 1;
  padding: 40px;
    min-height: calc(100vh - 104px);
}*/

main h1 {
  height: 80px;
  margin: -25px -25px 25px -25px;
  padding: 0 25px;
  line-height: 76px;
  font-size: 24px;
  font-weight: 400;
  background: #000b1d;
}
.flex-grid {
  display: flex;
}

.flex-grid > div {
  flex:1;
  padding: 15px;
  border: 1px solid #99FF32;
  border-radius: 5px;
  /*background: #001636;*/
  background: rgba(0, 22, 54, .9);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
}
.flex-grid div.emptydiv{
  padding:0px;
  font-size: 12px;
  box-shadow: none;
  border: 0px solid #22395A;
  justify-content: center;
}
.flex-grid div.emptydiv p{
color:#fff;
}
/*getting started styles*/
.flex-grid div.start{
  padding:0px;
  font-size: 12px;
  box-shadow: none;
  border: 1px solid #22395A;
}
.flex-grid div.start div.startnumber{
  background: #22395A;
color: #fff;
padding: 5px;
font-weight: bold;
border-radius: 5px 0px 0px 5px;
font-size: 24px;
min-width:56px;
text-align: center;
}
.flex-grid div.start div.starttext{
padding:10px;
}
/*star player styles*/
.flex-grid div.starplayer{
  flex:1;
  padding: 15px;
  border: 1px solid #71a0e2;
  border-radius: 5px;
  /*background: rgb(247,172,31);
  background: linear-gradient(189deg, rgba(247,172,31,1) 0%, rgba(203,140,13,1) 63%);*/
  background: rgb(113,160,226);
  background: linear-gradient(189deg, rgba(113,160,226,1) 0%, rgba(34,57,90,1) 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  color: #fff;
}
.flex-grid div.starplayer div div h2 {
  margin: 0px;
  padding: 3px 10px;
  font-size: 16px;
  font-weight: bold;
  background: rgba(144, 180, 230, 0.5);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom: 1px solid #2f507e;
  border-top: 1px solid #9fc3f6; 
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
}
.flex-grid div.starplayer div div img{
  /*scale: 95%;*/
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;  
}
/* .flex-grid > div:last-child {
  margin-right: 0;
} */

.flex-grid h2 {
  /* 
  padding: 12px 15px; */
  margin: -15px -15px 15px -15px;
  padding: 5px 10px 5px 5px;
  font-size: 16px;
  font-weight: 600;
  background: #000b1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #99FF32;
	text-transform: uppercase;
	color: #fff;
  display: flex;
  align-items: center;
}
.flex-grid h2 img {
  margin-right: 10px;
}
/* IAN TO REMOVE */
/* .flex-grid li {
  position: relative;
  margin: 0 0 10px;
  padding: 0 0 0 25px;
} */

/* .flex-grid li:before {
  content: '\f00c';
  font: normal 16px fontawesome;
  top: 0;
  left: 0;
  color: #999;
} */
thead tr td{
  border-bottom: 1px solid #99FF32;	
}
tbody tr.odd td{
background-color:#001636!important;	
}
.teamedit-bg{
  
    /*background: #000B1DA3;*/
    margin-left: 0 !important;
    padding-bottom: 20px;
    padding-top:54px;

}
.teamedit-bg div.row.g-0 div.col-12.col-sm-12.col-md-4.col-lg-3.col-xl-3 div.card{
  border-radius:0px!important;
  border: none!important;
}
.teamedit3Col{
  background: #22395a;
  padding: 0px 10px!important;
background: rgba(255, 255, 255, 0.2);
border-radius: 0px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(143, 255, 250, 0.44);
background: rgb(255,255,255);
background: linear-gradient(95deg, rgba(255,255,255,0.25) 47%, rgba(255,255,255,0.2) 48%);
}
.results-challenge{
  font-size: 42px;
  text-align: center;
}
.result-challenge-teamname{
  font-size: 32px;
  text-align: center;
}
.team-tables-lists{
  display: flex;
}
.fl-main{
  display: flex;
  flex-direction: row;
}
.fl-main div{
flex:1;
}
.fl-col{
  flex-direction: column;
}
.mob-br{display: none;}
.winner{
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}
.input-group {
  display: flex;
  flex-wrap: wrap;
  width: inherit!important;
}
.stake-switch{
  padding: 0px 10px;
  width: 200px;
  text-align: center;
  background: #2C4A74;
  border-radius: 4px;
  box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.24);
  border: solid 1px #466897;
  align-items: baseline;
  display: flex;
  justify-content: center;
}
/*
** Media Queries
***********************************************************/
@media (max-width: 576px) {
  .css-MuiPaper-root-MuiDialog-paper img{
    max-width: 100px;
    object-fit: contain;
  }
  .MuiDialogContent-root > div:nth-child(1) > div:nth-child(2) > img:nth-child(1){
    max-width: 100px;
    object-fit: contain;   
  }
  .css-1y28ifk-MuiPaper-root-MuiDialog-paper {
    min-width: 90%;
  }
  .css-ypiqx9-MuiDialogContent-root {
    padding: 10px!important;
}
.css-1grg3cz-MuiTypography-root-MuiDialogTitle-root{
  margin-bottom:0px!important;
}
.player-select-grid{
  padding: 0px 0px 0px 0px!important;
}
  .mob-br{display: block;}
  /*card value*/
  .fl-row{
    flex-direction: row;
    display: flex;
    border: none !important;
    padding: 0px !important;
    box-shadow: none!important;
  }
  .emptydiv img{
    width: 100px;
    height:150px;
  }
  .fl-main{
    display: flex;
    flex-direction: column!important;
  }  
  .fl-col{
    flex-direction: column;
  }
  .flex-grid > div {
    flex:1;
    padding: 15px;
    border: 1px solid #99FF32;
    border-radius: 5px;
    /*background: #001636;*/
    background: rgba(0, 22, 54, .9);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  }
  .flex-grid div.emptydiv{
    padding: 2px !important;
    font-size: 12px;
    box-shadow: none;
    border: 0px solid #22395A;
  }
  .flex-grid div h2 {
    /* 
    padding: 12px 15px; */
    margin: -15px -15px 15px -15px;
    padding: 5px 10px 5px 5px;
    font-size: 16px;
    font-weight: 600;
    background: #000b1d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #99FF32;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .flex-grid div h2 img {
    margin-right: 10px;
  }
  /*button styles*/
  button.green-btn{
    padding: 6px!important;
    line-height: 1.05em!important;
  }
  /*popup styles*/

.upgrade-poppic{
  display:flex;
  width:40%;
  align-items: flex-start;
  }
  .upgrade-poppic img{
  object-fit: contain;
  width: 100px;
    }
   .upgrade-statstable{
    flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  width: 350px;
  border: 1px solid rgb(153, 255, 50);
  border-radius: 5px;
   } 
  /*dash league table*/
  .db-lt-play {
    width: 15%;
    justify-content: center;
    display: flex;
  }
  .db-lt-pos {
    width: 10%;
    justify-content: center;
    display: flex;
  }
  .db-lt-teamname {
    width: 42%;
  }
  .modal-content {
    background-color: #fff;
    margin: auto;
  padding: 40px 0px;
    width: 100%!important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  }
  
  .modal-close{
    top: 40px;
    position: absolute;
    right: 0px;
  }
  .team-tables-lists{
    display: block;
  }
  .results-challenge{
    font-size: 32px;
    text-align: center;
  }
  .result-challenge-teamname{
    font-size: 22px;
    text-align: center;
  }
  /*results modal*/
  .results-modal{
    background: #fff;
    padding: 0px;
    width: 100%;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .results-top img{
    width: 362px;
  }
  .modal-close img{
    width: 42px;
  }
  .results-top{
    height: 131px;
    margin-top: -109px;
    margin-left: -14px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .results-score-row{
    background: #000;
    border-radius: 20px;
    width: 96%;
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
    z-index: 5;
    margin-top: -50px;
    flex-direction: row;
    display: flex;
    padding: 10px 10px;
  }
  .team1-wrap{
    flex-grow: 2;	
    width: 35%;
    }
    .team1{
      color: #fff;
      text-align: right;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }
    .manager1{
      color: #999;
      font-size: 14px;
      text-align: right;
    }
    .team2-wrap{
    flex-grow: 2;	
    width: 35%;
    }
    .team2{
      color: #fff;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }
    .manager2{
      color: #999;
      font-size: 14px;
    }
  .scoreboard{
    flex-grow: 1;
    z-index: 6;
    background-image: linear-gradient(to bottom, transparent 50%, #000 50%), linear-gradient(to right, #222 50%, #000 50%);
    background-size: 5px 5px, 5px 5px;
    border: ridge 5px #333;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
    margin: -20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
  .score{
    text-align: center;
    width: 120px;
    font-size: 29px;
    font-weight: bold;
    color: #9f3;
  }
  .results-lists{
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0px 5px;
    
  }
  .team1-list{
    flex-grow: 1;
    padding-right: 3px;
  }
  .team2-list{
    flex-grow: 1;
    padding-left: 3px;
  }
  .px-4 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .th-pitch-wrapper {
    background-image: url(/public/images/New-pitch-mob.jpg);
    background-attachment: scroll;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    height: 449px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .pitch-wrapper {
    margin: 15px 0px;
    background-image: url("/public/images/New-pitch-Large.jpg");
    background-attachment: scroll;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: solid 2px #000b1d;
  box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.65);
  padding-top: 10px;
  color: #fff;
  z-index: 5;
  position: relative;
}
.pitch-positions{
display: none;
}
/*-- card layout---*/
.css-1ujbpwy {
  width: 20px;
}
.card-box{
  text-align: center;
  width: 78px;

  }
  .card-box:hover{
    text-align: center;
    width: 78px;

    }

.card-box p a{
	font-size:7px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
  line-height: 9px;
}
.card-box p a:hover{
	font-size:7px;
	color: #fff;
	text-decoration: underline;
	font-weight: 600;
}
.card-box p{
margin:0px;
	padding: 1px;
  margin-top: -3px;
  margin-bottom: 5px;
}
.icon-box {
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  gap: 2px;
}
.row div.col-12.col-sm-12.col-md-8.col-lg-6.col-xl-6.pitch-width{
  --bs-gutter-x: 0rem !important; 
}
.row {
  --bs-gutter-x: 0rem!important;
}
.teamName{
  font-size:10px;
}
div.card-box-sm div.icon-box div div.MuiBox-root.css-1ujbpwy {
  width: 15px;
}
div.card-box-sm div.icon-box div svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-1efevc0-MuiSvgIcon-root{
  font-size: 1rem;
}
div.card-box-sm div.icon-box div svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-evk5x1-MuiSvgIcon-root {
  font-size: 1rem;
}
.card-box-sm {
  width:65px;
            }
            .card-box-sm:hover{
              text-align: center;
              width: 65px;
          
              }
  .card-box-sm div.icon-box {
    padding: 2px 0px;
  }
  .card-box-sm p a {
    font-size: 9px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    line-height: 6px;
  }
  .card-box-sm p a span{
  margin-top: 5px;
  }
  .card-box-sm p {
    background-color: rgba(0,0,0,0.0);
    margin:2px 0px;
      padding: 2px;
      border: solid 0px rgba(0,0,0,0.0);
      line-height: 9px;
  }
  .card-box-sm span {
    font-size: 9px;
  }
  div.card-box-sm div.icon-box div.flip-box {
    background-color: transparent;
    width: 26px;
    height: 38px;
    perspective: 1000px;
  }
  div.card-box-sm div.icon-box div.flip-box div.flip-box-inner div.flip-box-front img{
    width: 26px;
    height: 38px;	
  }
  div.card-box-sm div.icon-box div.flip-box div.flip-box-inner div.flip-box-front {
    position: absolute;
    font-size: small;
    width: 26px;
    height: 38px;
    backface-visibility: hidden;
  }
}
@media (max-width: 811px) {
  .mob-br{display: block;}
  /*card value*/
  .fl-row{
    flex-direction: row;
    display: flex;
    border: none !important;
    padding: 0px !important;
    box-shadow: none!important;
  }
  .emptydiv img{
    width: 100px!important;
    height:150px!important;
  }
  .fl-main{
    display: flex;
    flex-direction: row;
  }  
  .fl-col{
    flex-direction: column;
  }
  .flex-grid > div {
    flex:1;
    padding: 15px;
    border: 1px solid #99FF32;
    border-radius: 5px;
    /*background: #001636;*/
    background: rgba(0, 22, 54, .9);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  }
  .flex-grid div.emptydiv{
    padding: 2px !important;
    font-size: 12px;
    box-shadow: none;
    border: 0px solid #22395A;
  }
  .flex-grid div h2 {
    /* 
    padding: 12px 15px; */
    margin: -15px -15px 15px -15px;
    padding: 5px 10px 5px 5px;
    font-size: 16px;
    font-weight: 600;
    background: #000b1d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #99FF32;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .flex-grid div h2 img {
    margin-right: 10px;
  }
  /*button styles*/
  button.green-btn{
    padding: 6px!important;
    line-height: 1.05em!important;
    min-height:40.5px;
  }
.teamedit3Col{
  margin-top: 20px!important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
 /*card value*/
 .fl-row{
  flex-direction: row;
  display: flex;
  border: none !important;
  padding: 0px !important;
  box-shadow: none!important;
}
.emptydiv img{
  width: 100px!important;
  height:150px!important;
}
.fl-main{
  display: flex;
  flex-direction: row;
}  
.fl-col{
  flex-direction: column;
}
.flex-grid > div {
  flex:1;
  padding: 15px;
  border: 1px solid #99FF32;
  border-radius: 5px;
  /*background: #001636;*/
  background: rgba(0, 22, 54, .9);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
}
.flex-grid div.emptydiv{
  padding: 2px !important;
  font-size: 12px;
  box-shadow: none;
  border: 0px solid #22395A;
}
.flex-grid div h2 {
  /* 
  padding: 12px 15px; */
  margin: -15px -15px 15px -15px;
  padding: 5px 10px 5px 5px;
  font-size: 14px;
  font-weight: 600;
  background: #000b1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #99FF32;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
}
.flex-grid div h2 img {
  margin-right: 10px;
}
/*button styles*/
button.green-btn{
  padding: 6px 12px!important;
  line-height: 1.05em!important;
}
 /*results game screen*/
 .modal-content {
  background-color: #fff;
  margin: auto;
padding: 40px 0px;
  width: 90%!important;
display: flex;
justify-content: center;
flex-direction: column;
border-radius: 10px!important;
}

.modal-close{
  top: 16px;
  position: absolute;
  right: -22px;
}
.modal-close img{
  width: 42px!important;
}
/*results modal*/
.results-modal{
  background: #fff;
  padding: 0px;
  width: 100%;
  z-index: 0;
display: flex;
justify-content: center;
}
.results-top{
  height: 202px;
  margin-top: -75px;
  margin-left: -18px;
  position: relative;
}
.results-top img {
  width: 722px;
}
.results-score-row{
  background: #000;
  border-radius: 90px;
  width: 100%;
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
z-index: 5;
margin-top: -105px;
  flex-direction: row;
display: flex;
  padding: 10px 10px;
}
.team1-wrap{
  flex-grow: 2;	
  width: 40%;
  }
  .team1{
    color: #fff;
    text-align: right;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
  }
  .manager1{
    color: #999;
    text-align: right;
  }
  .team2-wrap{
  flex-grow: 2;	
  width: 40%;
  }
  .team2{
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
  }
  .manager2{
    color: #999;
  }
.scoreboard{
flex-grow: 1;
  z-index: 6;
  background-image: linear-gradient(to bottom, transparent 50%, #000 50%), linear-gradient(to right, #222 50%, #000 50%);
  background-size: 5px 5px, 5px 5px;
  border: ridge 5px #333;
  border-radius: 10px;
box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.25);
  margin: -20px 20px;
display: flex;
align-items: center;
justify-content: center;
width: 20%;
}
.score{
text-align: center;
width:120px;
font-size: 60px;
font-weight: bold;
color: #9f3;	
}
.results-lists{
  display: flex;
  flex-direction: row;
  height: 500px;
  padding: 10px;
  
}
.team1-list{
  flex-grow: 1;
  padding-right: 3px;
}
.team2-list{
  flex-grow: 1;
  padding-left: 3px;
}
  
}
@media (min-width: 1025px) and (max-width: 1199px) {
  /*card value*/
  .fl-row{
    flex-direction: row;
    display: flex;
    border: none !important;
    padding: 0px !important;
    box-shadow: none!important;
  }
  .emptydiv img{
    width: 100px!important;
    height:150px!important;
  }
  .fl-main{
    display: flex;
    flex-direction: row;
  }  
  .fl-col{
    flex-direction: column;
  }
  .flex-grid > div {
    flex:1;
    padding: 15px;
    border: 1px solid #99FF32;
    border-radius: 5px;
    /*background: #001636;*/
    background: rgba(0, 22, 54, .9);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
    box-shadow: 0px 10px 20px 0px rgba(0,11,29,1);
  }
  .flex-grid div.emptydiv{
    padding: 2px !important;
    font-size: 12px;
    box-shadow: none;
    border: 0px solid #22395A;
  }
  .flex-grid div h2 {
    /* 
    padding: 12px 15px; */
    margin: -15px -15px 15px -15px;
    padding: 5px 10px 5px 5px;
    font-size: 16px;
    font-weight: 600;
    background: #000b1d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #99FF32;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .flex-grid div h2 img {
    margin-right: 10px;
  }
  /*button styles*/
  button.green-btn{
    padding: 6px!important;
    line-height: 1.05em!important;
  }
}
@media only screen and (min-device-width: 577px) and (max-device-width: 1199px){ 
 
  .pitch-wrapper {
    margin: 0px 0px;
    background-image: url("/public/images/New-pitch-Large.jpg");
    background-attachment: scroll;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: solid 5px #000b1d;
  box-shadow: 0px 0px 50px 2px rgba(0,0,0,0.75);
  padding-top: 5px;
  color: #fff;
  z-index: 5;
  position: relative;
}
  .flex-grid {
    flex-direction: column;
  }

  .flex-grid > div {
    margin-right: 0;
  }
  .pitch-positions{
    display: none;
    }
    h5.card-title {
      font-size: 14px;
    }
    button.green-btn{
padding: 6px;
    }
    button.blue-btn{
      padding: 6px;
          }
          .css-1ujbpwy {
            width: 17px;
        }
          .card-box-sm {
width:70px;
          }
          .card-box-sm:hover {
            width:70px;
                      }
.card-box-sm div.icon-box {
  padding: 2px 0px;
}
.card-box-sm p a {
  font-size: 9px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  line-height: 6px;
}
.card-box-sm p a span{
margin-top: 5px;
}
.card-box-sm p {
  background-color: rgba(0,0,0,0.0);
  margin:2px 0px;
    padding: 2px;
    border: solid 0px rgba(0,0,0,0.0);
    line-height: 9px;
}
.card-box-sm span {
  font-size: 9px;
}
div.card-box-sm div.icon-box div.flip-box {
  background-color: transparent;
  width: 26px;
  height: 38px;
  perspective: 1000px;
}
div.card-box-sm div.icon-box div.flip-box div.flip-box-inner div.flip-box-front img{
  width: 26px;
  height: 38px;	
}
div.card-box-sm div.icon-box div.flip-box div.flip-box-inner div.flip-box-front {
  position: absolute;
  font-size: small;
  width: 26px;
  height: 38px;
  backface-visibility: hidden;
}
          }

@media (min-width: 1200px) {
   /*card value*/
 .fl-row{
  flex-direction: row;
  display: flex;
  border: none !important;
  padding: 0px !important;
  box-shadow: none!important;
}
.flex-grid div.emptydiv{
  padding: 2px !important;
  font-size: 12px;
  box-shadow: none;
  border: 0px solid #22395A;
}
  .emptydiv img{
    width: 100px;
    height:150px;
  }
    /*button styles*/
    button.green-btn{
      padding: 6px!important;
      line-height: 1.05em!important;
      min-height:40.5px;
    }
  .card-boxLG {
    text-align: center;
    width: 230px;
    margin: auto;
  }
  .flip-box-frontLG img {
    width: 100px;
    height: 150px;
    margin-bottom: -5px;
}
.pitch-positions{
  display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: auto;
text-align: right;
font-size: 3em;
font-weight: 900;
color: #fff;
opacity: 0.2;
}
h5.card-title {
  font-size: 14px!important;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
h5.card-title div button{
  padding: 0 4px;
}
h5.card-title div div img{
  width: 32px;
  height: 32px;
}
  }
  @media only screen and (min-width: 1440px) {
    .flex-grid div.emptydiv{
      padding: 2px !important;
      font-size: 12px;
      box-shadow: none;
      border: 0px solid #22395A;
    }
      .emptydiv img{
        width: 150px;
        height:225px;
      }
        /*button styles*/
        button.green-btn{
          padding: 6px 12px!important;
          line-height: 1.05em!important;
        }
    h5.card-title {
      font-size: 14px!important;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
    }
    h5.card-title div button{
      padding: 2px 6px;
    }
  .card-boxLG {
    text-align: center;
    width: 230px;
    margin: auto;
  }
    .flip-box-frontLG img {
    width: 200px;
    height: 300px;
    margin-bottom: -5px;
  }
  .pitch-positions{
    display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: auto;
 text-align: right;
 font-size: 3em;
 font-weight: 900;
 color: #fff;
 opacity: 0.1;
 }
  }
  @media only screen and (min-width: 1680px) {
    h5.card-title {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
    }
    h5.card-title div button{
      padding: 6px 16px;
    }
  }
/* Noel stuff */
.playerSearchResultBox{
  display: flex;
  flex-direction: column;
  gap:5px;
  border: 2px solid #001636;
  padding: 2px;
  align-items: center;
}

.playerSearchResultBox:hover{
  cursor: pointer;
  border: 2px solid #99ff32;
}

/* style={{
  border: "2px solid #001636",
  padding: "10px",

  // "&:hover": {
  //   cursor: "pointer",
  //   border: "2px solid #99ff32",
  // },
}} */

.centerMyForm {
  justify-content: center;
  align-items: center;
}

.MuiDataGrid-columnHeaders{
  border-bottom:1px solid #22395a !important;
}

/* Spin match score */


.spinScoreHome::after{
  content: counter(count);
  animation: counter 1s linear infinite alternate;
  counter-reset: count 0;
}

.spinScoreAway::after{
  content: counter(count);
  animation: counter 1s linear infinite alternate-reverse;
  counter-reset: count 0;
}

/* LEAGUE TABLE */
.leagueTablePositionCol {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leagueTableTeamNameCol{
  flex-grow: 4;
  display: flex;
  align-items: center;
}
.leagueTableTeamNameColCell{
  flex-grow: 4;
  display: flex;
  align-items: center;
  border-top: 1px solid #22395a;
 
  border-bottom: 1px solid #22395a;
}
.leagueTableManagerCol{
  width:200px;
  display: flex;
  align-items: center;
}
.leagueTableManagerColCell{
  width:200px;
  display: flex;
  align-items: center;
  border-top: 1px solid #22395a;
  border-bottom: 1px solid #22395a;
}
.leagueTableWLD{
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leagueTableWLDCell{

  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #22395a;
  border-bottom: 1px solid #22395a;
  font-weight: bold;
}
.leagueTablePTS{
 
   width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.leagueTablePTSCell{

  width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-top: 1px solid #22395a;
   border-bottom: 1px solid #22395a;
   font-weight: bold;
}
.leagueTableFitness{

    width: 140px;
    display: flex;
    align-items: center;
   justify-content: center;
}
.leagueTableFitnessCell{
  width: 140px;
  display: flex;
  align-items: center;
 justify-content: center;
 border-top: 1px solid #22395a;
 border-bottom: 1px solid #22395a;
}
.leagueTablePlay{
  width: 80px;
  display: flex;
    align-items: center;
   justify-content: center;
   margin-right: 5px;
}
.leagueTablePlayCell{
  width: 80px;
  display: flex;
  align-items: center;
   justify-content: center;
   border-top: 1px solid #22395a;
   border-bottom: 1px solid #22395a;
  border-right: 1px solid #22395a;
  margin-right: 5px;
}

@media (max-width: 900px){
  .leagueTablePositionCol {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  .leagueTableTeamNameCol{
    width: 150px;
    display: flex;
    align-items: center;
    font-size: 10px;
   
  }
  .leagueTableTeamNameColCell{
    height: 40px;
    width: 150px;
    display: flex;
    flex-direction: column;
   
    justify-content: center;
    align-items: flex-start;
    font-size: 10px;
    border-top: 1px solid #22395a;
   
    border-bottom: 1px solid #22395a;
   
  }
  .leagueTableManagerCol{
    width:130px;
    display: flex;
    align-items: center;
    font-size: 10px;
  }
  .leagueTableManagerColCell{
    width:130px;
    display: flex;
    align-items: center;
    border-top: 1px solid #22395a;
    border-bottom: 1px solid #22395a;
    font-size: 10px;
  }
  .leagueTableWLD{
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
  .leagueTableWLDCell{
  
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #22395a;
    border-bottom: 1px solid #22395a;
    font-weight: bold;
    font-size: 10px;
  }
  .leagueTablePTS{
 
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
 }
 .leagueTablePTSCell{
 
   width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #22395a;
    border-bottom: 1px solid #22395a;
    font-weight: bold;
    font-size: 10px;
 }
 .leagueTableFitness{
 
     width: 80px;
     display: flex;
     align-items: center;
    justify-content: center;
    font-size: 10px;
 }
 .leagueTableFitnessCell{
   width: 80px;
   display: flex;
   align-items: center;
  justify-content: center;
  border-top: 1px solid #22395a;
  border-bottom: 1px solid #22395a;
  font-size: 10px;
 }
 .leagueTablePlay{
  width: 50px;
  display: flex;
    align-items: center;
   justify-content: center;
   font-size: 10px;
}
 .leagueTablePlayCell{
  width: 50px;
  display: flex;
    align-items: center;
   justify-content: center;
   border-top: 1px solid #22395a;
   border-bottom: 1px solid #22395a;
  border-right: 1px solid #22395a;
  font-size: 10px;
  margin-right: '30px';
}
}

/* PLAYER SEARCH */
.playerSearchImage{
  width: 200px;
  height:300px;
  z-index:0;
}
.playerSearchAttributes{
  display: flex;
 flex-direction: column;
  width: 200px;
  background-color: "#001636";
  color: #fff;
  border-radius:3px;
  border: 1px solid #99ff32;
  font-size: 14px;
}
.playerSearchAttributeLabel{
  width:100px;
  margin-left: 10px;
}
.playerSearchTotalBox{
  
    display: flex;
    gap: 5px;
    border-top: 1px solid #99ff32;
    border-bottom: 1px solid #99ff32;
    margin-top: 2px;
}
.playerSearchTotalLabel{
    width: 100px;
    margin-left: 10px;
    font-weight: bold;
}
.playerSearchButtonsBox{
  display: flex;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.playerSearchButtonsBox2{
  display: flex;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  align-items: end;
  z-index: 2;
  position: relative;
  bottom: 30px;
  margin-top:-64px;
}
.playercolgap{
  gap:20px;
}
.css-1m91gzt-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%)!important;
}
.green-btn.disabledbtn.css-1m91gzt-MuiButtonBase-root-MuiButton-root::before {
background: none!important;
}
@media (max-width: 900px){
  .playerSearchImage{
    width: 170px;
    height:243px
  }
  .playercolgap{
    gap:10px;
  }
  .playerSearchAttributes{
    display: flex;
    flex-direction: column;
    width: 170px;
    background-color: "#001636";
    color: #fff;
    border-radius:3px;
    border: 1px solid #99ff32;
    font-size: 10px;
  }

  .playerSearchAttributeLabel{
    width:75px;
    margin-left: 10px;
  }

  .playerSearchTotalBox{
    
    display: flex;
    gap: 5px;
    border-top: 1px solid #99ff32;
    border-bottom: 1px solid #99ff32;
    margin-top: 2px;
  }

  .playerSearchTotalLabel{
    width: 75px;
    margin-left: 10px;
    font-weight: bold;
  }
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
 
}


/* Neftyblocks Market */
:root {
  /* the font-family for all text */
  --nefty-font-family: sans-serif;
  /* the default font size (best kept as is) */
  --nefty-font-size: 16px;
  /* the smaller font size (best kept as is) */
  --nefty-font-size--small: 14px;

  /* the minimum and maximum width of the cards (responsive)  */
  --nefty-card-size-min: 250px;
  --nefty-card-size-max: 1fr;

  /*  the space between cards */
  --nefty-cards-gap: 28px;

  /* border radius for all roundings of inputs, buttons, images */
  --nefty-radius: 6px;
  --nefty-radius-image: 12px;
  --nefty-radius-small: 12px;

  /* border color for buttons, inputs */
  --nefty-border: #99ff32;
  /* border color for the cards (if none use same color as card background) */
  --nefty-border-card: #99ff32;
  /* border thickness */
  --nefty-border-size: 1px;

  /* color primary text */
  --nefty-color: #fcfcfd;
  /* colors for non primary text */
  --nefty-color-secondary:#fcfcfd; 
  /*#777e90;*/

  /* buy button in the card (highlight) */
  --nefty-btn-primary: #99ff32;
  --nefty-btn-primary-bg: #001636;
  --nefty-btn-primary-border: #99ff32;
  --nefty-btn-primary--active: #001636;
  --nefty-btn-primary-bg--active: #99ff32;
  --nefty-btn-primary-border--active: #99ff32;

  /*
  background: #72dd07 none repeat scroll 0% 0%;
  border: 2px solid #57a20d;*/

  /* styling of pagination buttons and small reset button (will appear once you start filtering) */
  --nefty-btn-secondary: #99ff32;
  --nefty-btn-secondary-bg: #141416;
  --nefty-btn-secondary-border: #353945;
  --nefty-btn-secondary--active: #fcfcfd;
  --nefty-btn-secondary-bg--active: #353945;
  --nefty-btn-secondary-border--active: #353945;

  /* the filter button on mobile design */
  --nefty-btn-tertiary: #141416;
  --nefty-btn-tertiary-bg: #fcfcfd;
  --nefty-btn-tertiary-border: #fcfcfd;
  --nefty-btn-tertiary--active: #fcfcfd;
  --nefty-btn-tertiary-bg--active: #141416;
  --nefty-btn-tertiary-border--active: #fcfcfd;

  /* backgrounds */
  /* list background in filters (schemas) */
  --nefty-bg-list-item: #141416;
  /* card mint background */
  --nefty-bg-mint: #001636;
  /* all inputs (filters) */
  --nefty-bg-inputs: #001636;
  /* card background */
  --nefty-bg-card: #001636;
  /* loading state */
  --nefty-bg-loading: rgba(255, 255, 255, 0.05);
  /* fallback background color for images (best kept as is) */
  --nefty-bg-image: rgba(255, 255, 255, 0.05);
}